import Search from "./Search.js";
import School from "./School.js";
import SchoolEditor from "./SchoolEditor.js";
import Upload from "./Upload.js";
import Faq from "./Faq.js";
import PublicHeader from "./PublicHeader.js";
import InternalHeader from "./InternalHeader.js";
import Footer from "./Footer.js";
import Login from "./Login.js";
import Tutorial from "./Tutorial.js";
import Reports from "./Reports.js";
import KPI from "./KPI.js";
import DateRangeKPI from "./DateRangeKPI.js";
import Export from "./Export.js";
import Summary from "./Summary.js";
import { base_URL } from "../config/buildSettings.js";
import Register from "./Register.js";
import ANROnlineUsers from "./ANROnlineUsers.js";
import AdminResults from "./AdminResults.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { basename } from "../config/buildSettings.js";
import React, { Component } from "react";


class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false
    };
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("search") !== null) {
      localStorage.removeItem("search");
    }
    window.addEventListener("beforeunload", ev => {
      ev.preventDefault();
      if (localStorage.getItem("search") !== null) {
        localStorage.removeItem("search");
      }
    });
    
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", ev => {
      ev.preventDefault();
      if (localStorage.getItem("search") !== null) {
        localStorage.removeItem("search");
      }
    });
    if (localStorage.getItem("search") !== null) {
      localStorage.removeItem("search");
    }
  }

  handleLogin() {
    if (!this.state.isAuthenticated) {
      this.setState({
        isAuthenticated: true
      });
    }
  }

  handleLogout() {
    if (this.state.isAuthenticated) {
      let url = `${base_URL}/api/Account/Logout`;

      fetch(url, {
        method: "POST",
        credentials: "include"
      })
        .then(response => {
          // console.log(response);
          if (!response.ok) {
            throw Error(response.statusText);
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.setState({ isAuthenticated: false });
    }
  }

  render() {
    return (
      <Router basename={basename}>
        <div className="all-content">
          <div className="body-content">
            {/* Display public header and limited options for public user */}
            {!this.state.isAuthenticated && (
              <>
                <Route path="/" component={PublicHeader} />
                <Switch>
                  <Route exact path="/" component={Search} />
                  <Route path="/school/:school" component={School} />
                  <Route path="/faq" component={Faq} />
                  <Route path="/tutorial" component={Tutorial} />
                  <Route path="/summary" component={Summary} />
                  <Route
                    path="/"
                    render={props => (
                      <Login
                        {...props}
                        onLogin={(username, password) =>
                          this.handleLogin(username, password)
                        }
                      />
                    )}
                  />
                </Switch>
              </>
            )}

            {/* Display internal header and all options for internal user */}
            {this.state.isAuthenticated && (
              <>
                <Route
                  path="/"
                  render={props => (
                    <InternalHeader
                      {...props}
                      onLogout={() => this.handleLogout()}
                    />
                  )}
                />
                <Switch>
                  <Route exact path="/" component={Search} />
                  <Route exact path="/internalsearch" component={Search} />
                  <Route exact path="/test" component={Search} />
                  <Route exact path="/login" component={Search} />
                  <Route path="/school/:school" component={School} />
                  <Route path="/internalschool/:school" component={School} />
                  <Route path="/testschool/:school" component={School} />
                  <Route path="/upload" component={Upload} />
                  <Route path="/testupload" component={Upload} />
                  <Route path="/reports/:report" component={Reports} />
                  <Route path="/internalsummary" component={Summary} />
                  <Route path="/kpi" component={KPI} />
                  <Route path="/daterangekpi" component={DateRangeKPI} />
                  <Route path="/register" component={Register} />
                  <Route path="/anronlineusers" component={ANROnlineUsers} />
                  <Route path="/adminresults" component={AdminResults} />
                  <Route path="/testadminresults" component={AdminResults} />
                  <Route path="/faq" component={Faq} />
                  <Route path="/schooleditor/:env" component={SchoolEditor} />
                  <Route path="/export" component={Export} />
                </Switch>
              </>
            )}
          </div>

          {/* Always Display Footer */}
          <Route path="/" component={Footer} />
        </div>
      </Router>
    );
  }
}

export default App;
